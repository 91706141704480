import React from 'react';
import _ from 'lodash';

export class Canvas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: ["10", "ff", "df"],
            textcolor: 'dimgray',
            isDrawing: false,
            canvasSize: {
                height: 250,
                width: 250
            }, 
            canvasData: {
                height: 0,
                width: 0,
                pic: null
            },
            load: 0,
            selectedFile: null,
            selectedFileURI: "",
            isSelected: false,
            isFilePicked: false,
            previewSrc: ""
        };
        this.mouseDownHandler = this.mouseDownHandler.bind(this);
        this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
        this.mouseUpHandler = this.mouseUpHandler.bind(this);
        this.clearHandler = this.clearHandler.bind(this);
        this.clearStateHandler = this.clearStateHandler.bind(this);
        this.saveHandler = this.saveHandler.bind(this);
        this.saveFileHandler = this.saveFileHandler.bind(this);
        this.loadHandler = this.loadHandler.bind(this);
        this.loadFileHandler = this.loadFileHandler.bind(this);
        this.fileSubmissiondHandler = this.fileSubmissiondHandler.bind(this);
        this.canvasDataPic = this.canvasDataPic.bind(this);
        this.executeLoadFileHandler = this.executeLoadFileHandler.bind(this);
    }

    style = {
        border: '1px solid dimgray',
        backgroundColor: 'white',
        borderRadius: 6
    };

    handleWindowResize = _.debounce(() => {
        const canvasContainer = document.getElementById('canvasContainer');
        const canvasSize = canvasContainer.getBoundingClientRect()
        this.setState({ canvasSize: {height:250, width:canvasSize.width}});
      });
    
    componentDidMount() {
        //Initial Canvas width setting
        this.handleWindowResize();
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentDidUpdate() {
        
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }


    getContext() {
        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext("2d");
        return ctx;
    }

    mouseDownHandler(x, y) {
        this.setState(
            {
                isDrawing: true
            }
        )
        const ctx = this.getContext();
        ctx.moveTo(x, y);
    }

    mouseMoveHandler(x, y) {
        if (this.state.isDrawing) {
            const ctx = this.getContext();
            ctx.lineTo(x, y);
            ctx.stroke();
        } else {
            return;
        }

    }

    mouseUpHandler() {
        this.setState({isDrawing: false})
    }

    clearHandler() {
        const ctx = this.getContext()
        ctx.clearRect(0, 0, this.state.canvasSize.width, this.state.canvasSize.height)
        ctx.beginPath();
    }

    clearStateHandler() {
        this.setState(
            {canvasData: {
                height: 0,
                width: 0,
                pic: null
                }
            }
        )
    }

    saveHandler() {
        const canvas = document.getElementById('canvas');
        const canvasData = canvas.toDataURL()
        this.setState({
            canvasData: {
                height: this.state.canvasSize.height,
                width: this.state.canvasSize.width,
                pic: canvasData
            }
        })
    }

    saveFileHandler() {
        const canvas = document.getElementById('canvas');
        const canvasData = canvas.toDataURL()
        const a = document.createElement('a');
        a.href = canvasData;
        a.download = 'download.png';
        a.click()
    }

    loadHandler() {
        if (this.state.canvasData.pic) {
            const ctx = this.getContext();
            const img = new Image();
            img.src = this.state.canvasData.pic
            ctx.drawImage(img, 0, 0, this.state.canvasSize.width, this.state.canvasSize.height)
            const loadcount = this.state.load
            this.setState({load: loadcount + 1})
        }
    }

    executeLoadFileHandler() {
        const inputFile = document.getElementById("loadFile");
        inputFile.click();
    }

    loadFileHandler(e) {
        const file = e.target.files[0]
        if (file.type.match('image/*')) {
            const fileReader = new FileReader();
            fileReader.addEventListener('load',() => {
                console.log("loaded");
                const loadedFileURI = fileReader.result
                this.setState({
                    selectedFile: file,
                    selectedFileURI: loadedFileURI,
                    isSelected: true,
                    previewSrc: loadedFileURI
                });
            })
            if (file) {
                fileReader.readAsDataURL(file);
            }
        } else {
            console.log("not image")
        }
        
    }

    fileSubmissiondHandler() {
        if (this.state.selectedFileURI) {
            const ctx = this.getContext();
            const img = new Image();
            img.src = this.state.selectedFileURI
            ctx.drawImage(img, 0, 0, this.state.canvasSize.width, this.state.canvasSize.height)
            const loadcount = this.state.load
            this.setState({load: loadcount + 1, selectedFile: null, selectedFileURI: "", isSelected: false, previewSrc: ""})
        }
    }

    canvasDataPic() {
        if (!this.state.canvasData.pic) {
            return <p>No image in State</p>
        } else {
            return <img src={this.state.canvasData.pic} alt="Current pic in state"/>
        }
    }

    render() {
        return (
            <div className='column is-full'>
                <div style={{background: this.state.color}}>
                    <div id="canvasContainer">
                        <h3 className="subtitle is-4" style={{color: this.state.textcolor}}>
                            Canvas with onMouseDown, onMouseMove and onMouseUp
                        </h3>
                        <canvas
                            id="canvas"
                            style={this.style}
                            height="250"
                            width={this.state.canvasSize.width}
                            onMouseDown={e => this.mouseDownHandler(e.nativeEvent.offsetX, e.nativeEvent.offsetY)}
                            onMouseMove={e => this.mouseMoveHandler(e.nativeEvent.offsetX, e.nativeEvent.offsetY)}
                            onMouseUp={this.mouseUpHandler}
                            onMouseLeave={this.mouseUpHandler}
                        />
                        <div className='block mt-3'>
                            <button className="button is-responsive mr-3" onClick={this.clearHandler}>
                                Clear Canvas
                            </button>
                            <button className="button is-responsive mr-3" onClick={this.clearStateHandler}>
                                Clear State
                            </button>
                            <button className="button is-responsive mr-3" onClick={this.saveHandler}>
                                Save to State
                            </button>
                            <button className="button is-responsive mr-3" onClick={this.saveFileHandler}>
                                Save to File
                            </button>
                            <button className="button is-responsive mr-3" onClick={this.loadHandler}>
                                {this.state.canvasData.pic ? "Load from State" : "No Pic in State"}
                            </button>
                            <div className='block'></div>
                            <div>
                                <button className="button is-responsive mr-3" onClick={this.executeLoadFileHandler}>
                                    Upload File
                                </button>
                                <input hidden type="file" id="loadFile" accept=".png,.jpeg" onChange={this.loadFileHandler} />
                                <button className="button is-responsive mr-3" onClick={this.fileSubmissiondHandler}>
                                    Submit
                                </button>
                            </div>
                            {this.state.isSelected && 
                                <div>
                                    <p>Filename: {this.state.selectedFile.name}</p>
                                    <p>Filetype: {this.state.selectedFile.type}</p>
                                    <p>Size in bytes: {this.state.selectedFile.size}</p>
                                    <p>Width: {this.state.selectedFile.width}</p>
                                    <p>Height: {this.state.selectedFile.height}</p>
                                </div>
                            }
                            {this.state.previewSrc &&
                                <div>
                                    <img id="uploadedFilePreview" src={this.state.previewSrc} alt='preview'/>
                                </div>
                            }
                        </div>
                        <this.canvasDataPic />
                    </div>
                </div>
            </div>
        );
    }
}
