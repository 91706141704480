import React from 'react';
import Draggable from 'react-draggable';

export class DragWithDraggable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: ["10", "ff", "df"],
            buttonTextColor: 'dimgray',
            textcolor: 'dimgray',
            activeDrags: 0,
            deltaPosition: {
              x: 0, y: 0
            },
            controlledPosition: {
              x: -400, y: 200
            },
            divStyle: {
            }
        }
        this.onStart = this.onStart.bind(this);
        this.onStop = this.onStop.bind(this);
    }

    componentDidMount() {
        const style = {
            background: this.formatColor(this.state.color),
            borderRadius: 6,
            border: "thick double",
            borderWidth: 1
        }
        this.setState({divStyle: style});
    }

    formatColor(ary) {
        return "#" + ary.join("");
    }

    onStart = () => {
        const newActiveDrags = this.state.activeDrags + 1
        this.setState({activeDrags: newActiveDrags});
      };
    
    onStop = () => {
        const newActiveDrags = this.state.activeDrags - 1
        this.setState({activeDrags: newActiveDrags});
    };


    render() {
        const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
        return (
            <div className='column is-full'>
                <h4 className="subtitle is-4 is-responsive" style={{color: this.state.textcolor, paddingLeft: 5, paddingTop: 2}}>
                    Drag with <a target="_blank" rel="noreferrer" href="https://github.com/react-grid-layout/react-draggable"> Draggable </a>
                </h4>
                <p>
                    This sample is copied from Draggable <a target="_blank" rel="noreferrer" href="http://react-grid-layout.github.io/react-draggable/example/">Demo</a>.
                    The source is <a target="_blank" rel="noreferrer" href="https://github.com/react-grid-layout/react-draggable/blob/master/example/example.js">here</a>.
                </p>
                <div className='columns is-multiline'>
                    <div className='column is-full'>
                    <Draggable {...dragHandlers}>
                        <div className="box column is-one-quarter" style={this.state.divStyle}>I can be dragged anywhere</div>
                    </Draggable>
                    <Draggable axis="x" {...dragHandlers}>
                        <div className="box cursor-x column is-one-quarter" style={this.state.divStyle}>I can only be dragged horizonally (x axis)</div>
                    </Draggable>
                    <Draggable axis="y" {...dragHandlers}>
                        <div className="box cursor-y column is-one-quarter" style={this.state.divStyle}>I can only be dragged vertically (y axis)</div>
                    </Draggable>
                    <Draggable onStart={() => false}>
                        <div className="box column is-one-quarter" style={this.state.divStyle}>I don't want to be dragged</div>
                    </Draggable>
                    </div>
                    <div className='column is-full' style={{border: "dotted", height: '500px', position: 'relative', overflow: 'auto', padding: '0'}}> 
                        {/*<div style={{height: '1000px', width: '1000px', padding: '10px'}}>*/}
                        <div style={{height: '1000px', padding: '10px'}}>
                            <Draggable bounds="parent" {...dragHandlers}>
                            <div className="box column is-one-quarter" style={this.state.divStyle}>
                                I can only be moved within my offsetParent.<br /><br />
                                Both parent padding and child margin work properly.
                            </div>
                            </Draggable>
                            <Draggable bounds="parent" {...dragHandlers}>
                            <div className="box column is-one-quarter" style={this.state.divStyle}>
                                I also can only be moved within my offsetParent.<br /><br />
                                Both parent padding and child margin work properly.
                            </div>
                            </Draggable>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}