import React, { useState } from "react";

const CreateInputs = (props) => {
    const inputList = props.data.map((item, key) => {
        return (
                <option
                    key={`singleSelect_${key}`}
                    value={item.name}
                >
                {item.name}
                </option>
                
        )
    })

    return (
        <div>
            <form method="post" onSubmit={props.submitHandler}>
                <p className="mb-3">Single Select Item</p>
                <div className="select is-normal" style={{width:"100%"}}>
                <select
                    name="singleSelect"
                    id="singleSelectInput"
                    onChange={props.changeHandler}
                    style={{width: "100%"}}
                >
                    {inputList}
                </select>
                </div>
                <button
                    className="button is-responsive mt-3"
                    type="submit"
                >
                    Submit
                </button>
            </form>
        </div>
    )
}

const CurrentSelectedView = (props) => {

    return (
        <div className="column">
            <p className="mb-3">Current Selected</p>
            {props.selected}
        </div>
    )

}

const SubmittedView = (props) => {

    return (
        <div className="column notification is-primary mt-3">
            <p className="mb-3">Submitted</p>
            {props.submitted ? props.submitted : "Not selected"}
        </div>
    )

}

export function SingleSelect (props) {

    const [titleColor] = useState('dimgray')
    const [selected, setSelected] = useState(`${props.data[0].name}`)
    const [submitted, setSubmitted] = useState("")

    const changeHandler = (e) => {
        setSelected(e.target.value)
    }

    const submitHandler = (e) => {
        e.preventDefault();
        setSubmitted(selected)
    }

    return (
        <div className='column is-full' >
        <h4 className="subtitle is-4 is-responsive" style={{color: titleColor, paddingLeft: 5, paddingTop: 2}}>
            Single Select with select (onChange, onSubmit)
        </h4>
            <div className='columns'>
                <div className="column is-half">
                    <CreateInputs data={props.data} changeHandler={changeHandler} submitHandler={submitHandler}/>
                </div>
                <div className="column is-half columns is-flex is-flex-direction-column">
                    <CurrentSelectedView selected={selected}/>
                    {submitted ? <SubmittedView submitted={submitted}/> : ""}
                </div>
            </div>
        </div>
    )
}