import React, { useState } from "react";

const CreateInputs = (props) => {

    return (
        <div>
            <form method="post" onSubmit={props.submitHandler}>
                <p className="mb-3">Select Color</p>
                <div>
                <input type="color" id="colorSelect" name="colorSelect" value={props.selected} onChange={props.changeHandler}/>
                <label className="ml-3" htmlFor="colorSelect">Input type color</label>
                </div>
                <button
                    className="button is-responsive mt-3"
                    type="submit"
                >
                    Submit
                </button>
            </form>
        </div>
    )
}

const CurrentSelectedView = (props) => {

    return (
        <div className="column columns">
            <div className="column is-one-quarter">
                <p className="mb-3">Current Selected</p>
                {props.selected}
            </div>
            <div
            className="column"
            style={{
                height: "100%",
                width: "100%",
                borderRadius: 6,
                backgroundColor: props.selected
            }}></div>
        </div>
    )

}

const SubmittedView = (props) => {

    return (
        <div className="column notification is-primary mt-3 columns">
            <div className="column is-one-quarter">
                <p className="mb-3">Submitted</p>
                {props.submitted ? props.submitted : "Not selected"}
            </div>
            <div
            className="column"
            style={{
                height: "100%",
                width: "100%",
                borderRadius: 6,
                backgroundColor: props.submitted 
            }}></div>
        </div>
    )

}

export function ColorSelect (props) {

    const [titleColor] = useState('dimgray')
    const [selected, setSelected] = useState("#777777")
    const [submitted, setSubmitted] = useState("")

    const changeHandler = (e) => {
        console.log(e)
        setSelected(e.target.value)
    }

    const submitHandler = (e) => {
        e.preventDefault();
        setSubmitted(selected)
    }

    return (
        <div className='column is-full' >
        <h4 className="subtitle is-4 is-responsive" style={{color: titleColor, paddingLeft: 5, paddingTop: 2}}>
            Color (onChange, onSubmit)
        </h4>
            <div className='columns'>
                <div className="column is-half">
                    <CreateInputs selected={selected} changeHandler={changeHandler} submitHandler={submitHandler}/>
                </div>
                <div className="column is-half columns is-flex is-flex-direction-column">
                    <CurrentSelectedView selected={selected}/>
                    {submitted ? <SubmittedView submitted={submitted}/> : ""}
                </div>
            </div>
        </div>
    )
}