import React from 'react';
import { OnMouses } from './OnMouses';

export class OnMouse1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            textcolor: 'dimgray',
        }
    }

    render() {
        return (
            <div className='block is-desktop columns is-flex is-flex-direction-row is-flex-wrap-wrap'>
                <OnMouses event={'onClick'} />
                <OnMouses event={'onDoubleClick'} />
                <OnMouses event={'onContextMenu'} />
                <OnMouses event={'onMouseEnter'} />
                <OnMouses event={'onMouseLeave'} />
                <OnMouses event={'onMouseOver'} />
            </div>
        )
    }
}