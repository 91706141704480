import React from 'react';

export class OnMouses extends React.Component {
    constructor(props) {
        super(props);
        this.state = { color: ["10", "ff", "df"], textcolor: 'dimgray'};
        this.handler = this.handler.bind(this);
        this.contextMenuHandler = this.contextMenuHandler.bind(this);
    }

    content = {
        onClick: {
            title: 'onClick event',
            caption: 'Click',
            buttonTag: 'onClick'
        },
        onDoubleClick: {
            title: 'onDoubleClick event',
            caption: 'Double Click',
            buttonTag: 'onDoubleClick={this.handler}'
        },
        onContextMenu: {
            title: 'onContextMenu event',
            caption: 'Right Click',
            buttonTag: 'onContextMenu={this.contextMenuHandler}'
        },
        onMouseEnter: {
            title: 'onMouse Enter event',
            caption: 'Mouse Enter',
            buttonTag: 'onMouseEnter={this.handler}'
        },
        onMouseLeave: {
            title: 'onMouseLeave event',
            caption: 'Mouse Leave',
            buttonTag: 'onMouseLeave={this.handler}'
        },
        onMouseOver: {
            title: 'onMouseOver event',
            caption: 'Mouse Over',
            buttonTag: 'onMouseOver={this.handler}'
        }
    }

    buttonStyle = {

    }

    formatColor(ary) {
        return "#" + ary.join("");
    }

    isLight() {
        const rgb = [];
        this.state.color.forEach(element => {
            rgb.push(parseInt(element, 16));
        });
        return rgb.reduce((a,b) => a+b) < 127 * 3;
    }

    chooseColor() {
        const random = [];
        for (let i = 0; i < 3; i++) {
          random.push((Math.floor(Math.random()*256)).toString(16));
        }
        return random;
    }
    
    handler() {
        //console.log(this.state.color);
        this.setState({color: this.chooseColor()});
        this.isLight() ? this.setState({textcolor: 'dimgray'}) : this.setState({textcolor: 'floralwhite'});
    }

    contextMenuHandler(e) {
        e.preventDefault();
        //console.log(this.state.color);
        this.setState({color: this.chooseColor()});
        this.isLight() ? this.setState({textcolor: 'dimgray'}) : this.setState({textcolor: 'floralwhite'});
    }

    eventSwitch = () => {
        switch (this.props.event) {
            case 'onClick':
                return (
                    <button className="button is-responsive" onClick={this.handler}>
                        {this.content[this.props.event].caption}
                    </button>
                );
            case 'onMouseEnter':
                return (
                    <button className="button is-responsive" onMouseEnter={this.handler}>
                        {this.content[this.props.event].caption}
                    </button>
                );
            case 'onMouseLeave':
                return (
                    <button className="button is-responsive" onMouseLeave={this.handler}>
                        {this.content[this.props.event].caption}
                    </button>
                );
            case 'onDoubleClick':
                return (
                    <button className="button is-responsive" onDoubleClick={this.handler}>
                        {this.content[this.props.event].caption}
                    </button>
                );
            case 'onContextMenu':
                return (
                    <button className="button is-responsive" onContextMenu={this.contextMenuHandler}>
                        {this.content[this.props.event].caption}
                    </button>
                );
            case 'onMouseOver':
                return (
                    <button className="button is-responsive" onMouseOver={this.handler}>
                        {this.content[this.props.event].caption}
                    </button>
                );
            default:
                return (
                    <button className="button is-responsive" onClick={this.handler}>
                        Click
                    </button>
                );
        }
    }

    
    render() {
        return (
                <div className='column is-one-quarter' >
                    <div style={{background: this.formatColor(this.state.color), height: 100, borderRadius: 6}}>
                            <h4 className="is-4 is-responsive" style={{color: this.state.textcolor, paddingLeft: 5, paddingTop: 2}}>
                                {this.content[this.props.event].title}
                            </h4>
                            <div className="buttons is-centered">
                            <this.eventSwitch />
                            </div>
                    </div>
                </div>
        );
    }
}