import React from 'react';
import { ContentViewTemplate } from './ContentViewTemplate';
import { EmailInput } from '../FormEventHandlers/Email';
import { PasswordInput } from '../FormEventHandlers/Password';
import { TextInput } from '../FormEventHandlers/Text';
import { Textarea } from '../FormEventHandlers/Textarea';
import { TextareaWithMD } from '../FormEventHandlers/TextareaWithMD';

/*
const sampleList = [
    {
      id: 0,
      name: "Apple",
      quantity: 10,
      price: 100,
    },
    {
      id: 1,
      name: "Banana",
      quantity: 20,
      price: 200,
    },
    {
      id: 2,
      name: "Cherry",
      quantity: 30,
      price: 300,
    },
  ]
*/

const contents = [
    //id of Top have to be 0 because it is linked to mainTitle element.
    {id: 0, title: "Top"},
    {id: 1, title: "Email", content: <EmailInput/>, isFlex: true},
    {id: 2, title: "Password", content: <PasswordInput/>, isFlex: true},
    {id: 3, title: "Text", content: <TextInput/>, isFlex: true},
    {id: 4, title: "Textarea", content: <Textarea/>, isFlex: true},
    {id: 5, title: "TextareaWithMarkdown", content: <TextareaWithMD/>, isFlex: true},
  ]

export function FormEventHandlerCollection3 (props) {

    //Formikのバリデーションを試す　https://formik.org/
    return (
        <ContentViewTemplate contentPrefix="formEvents3" mainTitle="Form Event Handlers 3" contents={contents}/>
      );
}