import React, { useState } from "react";

const CreateInputs = (props) => {
    return (
        <div>
            <p className="mb-3">Current Selected</p>
            <div>
                <form method="post" onSubmit={props.submitHandelr}>
                    <div>
                        <label htmlFor="volume">Volume<br/>
                            <input className="rangeInput" type="range" id="volume" name="volume" list="tickmarks" style={{width:"95%"}}
                                    min={props.volumeRange.min} max={props.volumeRange.max} value={props.volume} onChange={props.rangeHandler}/>
                            <datalist id="tickmarks">
                                <option value="0"></option>
                                <option value="2"></option>
                                <option value="4"></option>
                                <option value="6"></option>
                                <option value="8"></option>
                                <option value="10"></option>
                            </datalist>
                        </label>
                    </div>
                    <div>
                        <label htmlFor="luminance">Luminance<br/>
                            <input type="range" id="luminance" name="luminance" list="scale" style={{width:"95%"}}
                                    min={props.luminanceRange.min} max={props.luminanceRange.max} step={props.luminanceRange.step} value={props.luminance} onChange={props.rangeHandler}/>
                            <datalist id="scale">
                                <option value="0"></option>
                                <option value="20"></option>
                                <option value="40"></option>
                                <option value="60"></option>
                                <option value="80"></option>
                                <option value="100"></option>
                            </datalist>
                        </label>
                    </div>
                    <button
                        className="button is-responsive mt-3"
                        type="submit"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    )
} 

const CurrentRangeView = (props) => {
    return (
        <div className="column">
            <p className="mb-3">Current Range Value</p>
            <ul>
                <li>
                    Volume: {props.volume}
                </li>
                <li>
                    Luminance: {props.luminance}
                </li>
            </ul>
        </div>
    )
}

const SubmittedView = (props) => {

    return (
        <div className="column notification is-primary mt-3">
            <p className="mb-3">Submitted</p>
            {JSON.stringify(props.submitted)}
        </div>
    )

}

export function Range (props) {

    const [titleColor] = useState('dimgray')
    const [volumeRange] = useState({min: 0, max: 10, step: 1})
    const [luminanceRange] = useState({min: 0, max: 100, step: 10})
    const [volume, setVolume] = useState(0);
    const [luminance, setLuminance] = useState(0);
    const [submitted, setSubmitted] = useState({});

    const rangeHandler = (e) => {
        switch (e.target.name) {
            case 'volume':
                setVolume(e.target.value)
                break;
            case 'luminance':
                setLuminance(e.target.value)
                break;
            default:
                break;
        }
    }

    const submitHandelr = (e) => {
        e.preventDefault();
        setSubmitted({
            volume: volume,
            luminance: luminance
        })
    }

    return (
        <div className='column is-full' >
        <h4 className="subtitle is-4 is-responsive" style={{color: titleColor, paddingLeft: 5, paddingTop: 2}}>
            Range (onChange, onSubmit)
        </h4>
            <div className='columns'>
                <div className="column is-half">
                    <CreateInputs volume={volume} volumeRange={volumeRange} luminance={luminance} luminanceRange={luminanceRange} rangeHandler={rangeHandler} submitHandelr={submitHandelr}/>
                </div>
                <div className="column is-half columns is-flex is-flex-direction-column">
                    <CurrentRangeView volume={volume} luminance={luminance}/>
                    {Object.keys(submitted).length ? <SubmittedView submitted={submitted}/> : ""}
                </div>
            </div>
        </div>
    )
}