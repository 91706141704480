import React, { useState } from "react";

const CreateInputs = (props) => {
    const inputList = props.data.map((item, key) => {
        return (
                <option
                    key={`multipleSelect_${key}`}
                    value={item.name}
                >
                {item.name}
                </option>
        )
    })

    return (
        <div>
            <form method="post" onSubmit={props.submitHandler}>
                <p className="mb-3">Multiple Select Item</p>
                <div className="select is-multiple is-normal" style={{width:"100%"}}>
                <select
                    name="multipleSelect"
                    id="multipleSelectInput"
                    multiple
                    style={{width: "100%"}}
                    onChange={props.changeHandler}
                >
                    {inputList}
                </select>
                </div>
                <button
                    className="button is-responsive mt-3"
                    type="submit"
                >
                    Submit
                </button>
            </form>
        </div>
    )
}

const CurrentSelectedView = (props) => {
    const checkedItems = props.selected.map((item, key) => {
        return (
            <li key={`selectedItem_${key}`}>
                {item}
            </li>
        )
    })

    return (
        <div className="column">
            <p className="mb-3">Current Selected</p>
            <ul>
                {checkedItems}
            </ul>
        </div>
    )

}

const SubmittedView = (props) => {

    const submittedItems = props.submitted.map((item, key) => {
        return (
            <li key={`submittedItem_${key}`}>
                {item}
            </li>
        )
    })

    return (
        <div className="column notification is-primary mt-3">
            <p className="mb-3">Submitted</p>
            <ul>
                {submittedItems}
            </ul>
        </div>
    )

}

export function MultipleSelect (props) {

    const [titleColor] = useState('dimgray')
    const [selected, setSelected] = useState([])
    const [submitted, setSubmitted] = useState([])

    const changeHandler = (e) => {
        const selectedOptions = e.target.selectedOptions
        const selectedOptionsValue = Object.keys(selectedOptions).map(key => selectedOptions[key].value)
        setSelected(selectedOptionsValue)
    }

    const submitHandler = (e) => {
        e.preventDefault();
        setSubmitted(selected)
    }

    return (
        <div className='column is-full' >
        <h4 className="subtitle is-4 is-responsive" style={{color: titleColor, paddingLeft: 5, paddingTop: 2}}>
            Multiple Select with select (onChange, onSubmit)
        </h4>
            <div className='columns'>
                <div className="column is-half">
                    <CreateInputs data={props.data} changeHandler={changeHandler} submitHandler={submitHandler}/>
                </div>
                <div className="column is-half columns is-flex is-flex-direction-column">
                    <CurrentSelectedView selected={selected}/>
                    {submitted.length ? <SubmittedView submitted={submitted}/> : ""}
                </div>
            </div>
        </div>
    )
}