import React from 'react';
import { ContentViewTemplate } from './ContentViewTemplate';
import { DateInput } from '../FormEventHandlers/Date';
import { DateTimeLocalInput } from '../FormEventHandlers/DateTimeLocal';
import { MonthInput } from '../FormEventHandlers/Month';
import { WeekInput } from '../FormEventHandlers/Week';
import { TimeInput } from '../FormEventHandlers/Time';
import { FileInput } from '../FormEventHandlers/File';
import { ImageInput } from '../FormEventHandlers/Image';

const contents = [
    //id of Top have to be 0 because it is linked to mainTitle element.
    {id: 0, title: "Top"},
    {id: 1, title: "Date", content: <DateInput/>, isFlex: true},
    {id: 2, title: "DateTimeLocal", content: <DateTimeLocalInput/>, isFlex: true},
    {id: 3, title: "Month", content: <MonthInput/>, isFlex: true},
    {id: 4, title: "Week", content:<WeekInput/>, isFlex: true},
    {id: 5, title: "Time", content:<TimeInput/>, isFlex: true},
    {id: 6, title: "File", content:<FileInput/>, isFlex: true},
    {id: 7, title: "Image", content:<ImageInput/>, isFlex: true},
  ]

export function FormEventHandlerCollection2 (props) {

    return (
        <ContentViewTemplate contentPrefix="formEvents2" mainTitle="Form Event Handlers 2" contents={contents}/>
      );
}