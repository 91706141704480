import React from 'react';

export class Drag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: ["10", "ff", "df"],
            buttonTextColor: 'dimgray',
            textcolor: 'dimgray',
            leftListDivStyle: {
                display: "grid",
                placeItems: "left",
                height: 150,
                borderRadius: 6,
                border: "dotted",
                borderColor: "dimgray",
                borderWidth: 2
            },
            rightListDivStyle: {
                display: "grid",
                placeItems: "left",
                height: 150,
                borderRadius: 6,
                border: "dotted",
                borderColor: "dimgray",
                borderWidth: 2
            },
            leftListData: this.props.data,
            rightListData: []
        }
        this.onDragStartHandelr = this.onDragStartHandelr.bind(this);
        this.onDragOverHandler = this.onDragOverHandler.bind(this);
        this.onDropHandler = this.onDropHandler.bind(this);
        this.onDragEnterHandler = this.onDragEnterHandler.bind(this);
        this.onDragLeaveHandler = this.onDragLeaveHandler.bind(this);
        this.listItems = this.listItems.bind(this);
    }

    componentDidMount(){

    }

    onDragStartHandelr(e) {
        e.dataTransfer.setData('text/plain', e.target.id)
    }

    onDragOverHandler(e) {
        e.preventDefault();
        e.stopPropagation()
        e.dataTransfer.dropEffect = 'copy'
    }

    onDropHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        const leftListItems = this.state.leftListData;
        const rightListItems = this.state.rightListData;
        let divStyle = {}
        for (const item of e.dataTransfer.items) {
            const {kind, type} = item;
            if (kind === 'string' && type === 'text/plain') {
                const draggedItemSide = e.dataTransfer.getData(type).split('_')[0];
                const dropTargetDivSide = e.target.id.split('_')[0]
                if (draggedItemSide !== dropTargetDivSide) {
                    const draggedItemKey = e.dataTransfer.getData(type).split('_')[1];
                    let targetItem = {};
                    switch (draggedItemSide) {
                        case 'rightList':
                            targetItem = rightListItems[draggedItemKey]
                            leftListItems.push(targetItem)
                            rightListItems.splice(draggedItemKey, 1)
                            divStyle = {...this.state.leftListDivStyle}
                            if (divStyle.backgroundColor) {
                                delete divStyle.backgroundColor
                            }
                            this.setState({leftListItems: leftListItems, rightListItems: rightListItems, leftListDivStyle: divStyle})
                            break
                        case 'leftList':
                            targetItem = leftListItems[draggedItemKey]
                            rightListItems.push(targetItem)
                            leftListItems.splice(draggedItemKey, 1)
                            divStyle = {...this.state.rightListDivStyle}
                            if (divStyle.backgroundColor) {
                                delete divStyle.backgroundColor
                            }
                            this.setState({leftListItems: leftListItems, rightListItems: rightListItems, rightListDivStyle: divStyle})
                            break
                        default:
                            break
                    }
                }
            }
        }
    }

    onDragEnterHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        let divStyle = {}
        switch (e.currentTarget.id) {
            case 'leftList':
                divStyle = {...this.state.leftListDivStyle}
                if (!divStyle.backgroundColor) {
                    divStyle.backgroundColor = "mistyrose"
                    this.setState({leftListDivStyle: divStyle})
                }
                break
            case 'rightList':
                divStyle = {...this.state.rightListDivStyle}
                if (!divStyle.backgroundColor) {
                    divStyle.backgroundColor = "mistyrose"
                    this.setState({rightListDivStyle: divStyle})
                }
                break
            default:
                break
        }
    }

    onDragLeaveHandler(e) {
        e.preventDefault();
        let divStyle = {}
        if (!e.currentTarget.contains(e.relatedTarget)) {
            switch (e.currentTarget.id) {
                case 'leftList':
                    divStyle = {...this.state.leftListDivStyle}
                    if (divStyle.backgroundColor) {
                        delete divStyle.backgroundColor
                        this.setState({leftListDivStyle: divStyle})
                    }
                    break
                case 'rightList':
                    divStyle = {...this.state.rightListDivStyle}
                    if (divStyle.backgroundColor) {
                        delete divStyle.backgroundColor
                        this.setState({rightListDivStyle: divStyle})
                    }
                    break
                default:
                    break
            }
        }
    }

    listItems(arg) {
        const listArr = [...arg.list]
        return (
            <ul id={arg.id} className="content">
                {listArr.map((item, key) => {
                    return (
                    <li
                        className="draggedItems"
                        key={key}
                        id={`${arg.id}_${key}`}
                        draggable="true"
                        onDragStart={this.onDragStartHandelr}
                    >
                        <span>{item.name}</span>
                    </li>
                    )
                })}
            </ul>
        )
    }

    render() {
        return (
            <div className='column is-full'>
                <h4 className="subtitle is-4 is-responsive" style={{color: this.state.textcolor, paddingLeft: 5, paddingTop: 2}}>
                    onDragStart, onDragOver, onDrop, onDragEnter and on onDragLeave
                </h4>
                <div className='columns'>
                    <div className="column is-half">
                        Draggable Items
                        <div
                            id="leftList"
                            style={this.state.leftListDivStyle}
                            onDragOver={this.onDragOverHandler}
                            onDrop={this.onDropHandler}
                            onDragEnter={this.onDragEnterHandler}
                            onDragLeave={this.onDragLeaveHandler}
                        >
                            <this.listItems list={this.state.leftListData} id="leftList"/>
                        </div>
                    </div>
                    <div className="column is-half">
                    Drag over or drop here
                        <div
                            id="rightList"
                            style={this.state.rightListDivStyle}
                            onDragOver={this.onDragOverHandler}
                            onDrop={this.onDropHandler}
                            onDragEnter={this.onDragEnterHandler}
                            onDragLeave={this.onDragLeaveHandler}
                        >
                            <this.listItems list={this.state.rightListData} id="rightList"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}