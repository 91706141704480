import React, { useState, useRef, useEffect } from "react";

const CreateInputs = (props) => {

    return (
        <div className="field columns is-flex is-flex-direction-column">
            <div className="column">
                <p>Input File</p>
            </div>
            <div className="column">
                <form method="post" onSubmit={props.submitHandler}>
                    <div className="file is-boxed" ref={props.inputContainerRef} >
                    <label className="file-label">
                        <input
                            className="file-input"
                            type="file"
                            accept="image/*"
                            id="fileInput"
                            name="fileInput"
                            onChange={props.changeHandler}
                        />
                        <span className="file-cta" style={{width: "80%"}}>
                            <span className="file-icon">
                                <i className="fas fa-upload"></i>
                            </span>
                            <span className="file-label">
                                Choose an image file…
                            </span>
                        </span>
                        <span className="file-name" ref={props.inputFileNameRef} style={{width: "80%"}}>
                        </span>
                    </label>
                    </div>
                    <button
                        className="button is-responsive mt-3"
                        type="submit"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    )
}

const CurrentSelectedView = (props) => {

    return (
        <div className="column">
            <div>
                <p className="mb-3">Current Selected</p>
                {!props.isSelected ? props.selected.name : "Not selectted"}
                <div ref={props.imageContainerRef} />
            </div>
        </div>
    )

}

const SubmittedView = (props) => {

    return (
        <div className="column notification is-primary mt-3">
            <div>
                <p className="mb-3">Submitted</p>
                {!Object.keys(props.submitted).length ? props.submitted.name : "Not submitted"}
                <div ref={props.submittedContainerRef} />
            </div>
        </div>
    )

}

export function FileInput (props) {

    const [titleColor] = useState('dimgray')
    const [submitted, setSubmitted] = useState({});
    const [selected, setSelected] = useState({});
    const [isSelected, setIsSelected] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const imageContainerRef = useRef(null)
    const inputContainerRef = useRef(null)
    const inputFileNameRef = useRef(null)
    const submittedContainerRef = useRef(null)

    const changeHandler = (e) => {
        const files = e.currentTarget.files;
		if (!files || files?.length === 0) return;
		const file = files[0];
        setSelected(file)
        setIsSelected(true)
    }

    useEffect(() => {
        if (!isSelected) return;
        const fileImage = new Image()
        const imageContainer = imageContainerRef.current
        if (!imageContainer) return;
        fileImage.src = window.URL.createObjectURL(selected);
        imageContainer.appendChild(fileImage)
        // Add className as "has-name" into input div
        const inputContainer = inputContainerRef.current
        inputContainer.classList.add = "has-name"
        // Add filename into span
        const inputFileName = inputFileNameRef.current
        inputFileName.innerHTML = selected.name
    }, [selected, isSelected])

    const submitHandler = (e) => {
        e.preventDefault();
        isSelected && setSubmitted(selected) 
        setIsSubmitted(true)
    }

    useEffect(() => {
        if (!isSubmitted) return;
        const fileImage = new Image()
        const imageContainer = submittedContainerRef.current
        if (!imageContainer) return;
        fileImage.src = window.URL.createObjectURL(submitted);
        imageContainer.appendChild(fileImage)
    }, [submitted, isSubmitted])

    return (
        <div className='column is-full' >
        <h4 className="subtitle is-4 is-responsive" style={{color: titleColor, paddingLeft: 5, paddingTop: 2}}>
            File
        </h4>
            <div className='columns'>
                <div className="column is-half">
                    <CreateInputs selected={selected} isSelected={isSelected} inputContainerRef={inputContainerRef} inputFileNameRef={inputFileNameRef} changeHandler={changeHandler} submitHandler={submitHandler}/>
                </div>
                <div className="column is-half columns is-flex is-flex-direction-column">
                    <CurrentSelectedView selected={selected} imageContainerRef={imageContainerRef}/>
                    {isSubmitted ? <SubmittedView submitted={submitted} submittedContainerRef={submittedContainerRef}/> : ""}
                </div>
            </div>
        </div>
    )
}