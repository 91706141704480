import React, { useState } from "react";
import DOMPurify from "dompurify";
import MarkdownIt from "markdown-it";

const md = new MarkdownIt();

const ValidateTextInput = (text) => {
    // 任意のバリデーションルールを設定
    if (text.length > 2048) {
      return false;
    }
    return true;
  }

const CreateInputs = (props) => {

    return (
        <div className="field columns is-flex is-flex-direction-column">
            <div className="column">
                <h5 className="block">Textarea with Markdown</h5>
                <div className="content">
                <p>テキストエリアの要件</p>
                <ul>
                    <li>2048文字未満</li>
                </ul>
                </div>
            </div>
            <div className="column">
                <form method="post" onSubmit={props.submitHandler}>
                    <label htmlFor="textarea">Textarea with Markdown: </label>
                    <p className="mb-3 control" style={{width: "80%"}} >
                        <textarea
                            className="textarea is-primary"
                            id="textarea"
                            name="textarea"
                            rows="8"
                            onChange={props.changeHandler}
                            defaultValue="e.g. mulitiple line text"
                        />
                    </p>
                    <button
                        className="button is-responsive mt-3"
                        type="submit"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    )
}

const CurrentSelectedView = (props) => {

    return (
        <div className="column">
            <div>
                <p className="mb-3">Current Selected</p>
                {props.selected}
            </div>
            <div
            className="column"
            style={{
                height: "100%",
                width: "100%",
                borderRadius: 6,
                backgroundColor: props.selected
            }}></div>
        </div>
    )

}

const SubmittedView = (props) => {

    return (
        <div className="column notification is-primary mt-3">
            <div>
                <p className="mb-3">Submitted</p>
                {props.submitted ? props.submitted : "Not selected"}
            </div>
        </div>
    )

}

export function TextareaWithMD (props) {

    const [titleColor] = useState('dimgray')
    const [submitted, setSubmitted] = useState("")
    const [selected, setSelected] = useState("Text example")

    const changeHandler = (e) => {
        setSelected(e.target.value)
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        if (!ValidateTextInput(selected)) {
            alert('テキストが2048文字を超えています');
            return;
        }

        //Sanitize Markdown
        const sanitizedContent = DOMPurify.sanitize(selected);

        //Convert Markdown to HTML
        const htmlContent = md.render(sanitizedContent);

        //Sanitize html again
        const sanitizedHtmlContent = DOMPurify.sanitize(htmlContent);

        setSubmitted(sanitizedHtmlContent)
    };

    return (
        <div className='column is-full' >
        <h4 className="subtitle is-4 is-responsive" style={{color: titleColor, paddingLeft: 5, paddingTop: 2}}>
            Textarea with Markdown
        </h4>
            <div className='columns'>
                <div className="column is-half">
                    <CreateInputs selected={selected} changeHandler={changeHandler} submitHandler={submitHandler}/>
                </div>
                <div className="column is-half columns is-flex is-flex-direction-column">
                    <CurrentSelectedView selected={selected}/>
                    {submitted ? <SubmittedView submitted={submitted}/> : ""}
                </div>
            </div>
        </div>
    )
}