import React from 'react'
import { ViewSelector } from './ViewSelector';
import './App.css';
import 'bulma/css/bulma.css'

function App() {

  return (
    <div>
      <section className="hero is-info">
        <div className='hero-body'>
        <h1 className="title">Welcome to yamadays learning site</h1>
        <h2 className="subtitle">HTML, JS & CSS samples with React and Bulma</h2>
        </div>
      </section>
      <div>
        <ViewSelector />
      </div>
    </div>
  );
}

export default App;
