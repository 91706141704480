import React from 'react';
import { ContentViewTemplate } from './ContentViewTemplate';
import { OnMouse1 } from '../MouseEventHandlers/OnMouse1';
import { OnMouse2 } from '../MouseEventHandlers/OnMouse2';
import { Drag } from '../MouseEventHandlers/Drag';
import { DragWithDraggable } from '../MouseEventHandlers/DragWithDraggable';
import { Canvas } from '../MouseEventHandlers/Canvas';


export function MouseEventHandlerCollection() {

    const sampleList = [
      {
        id: 0,
        name: "Apple",
        quantity: 10,
        price: 100,
      },
      {
        id: 1,
        name: "Banana",
        quantity: 20,
        price: 200,
      },
      {
        id: 2,
        name: "Cherry",
        quantity: 30,
        price: 300,
      },
    ]
    
    const contents = [
      //id of Top have to be 0 because it is linked to mainTitle element.
      {id: 0, title: "Top"},
      {id: 1, title: "Mouse Click, Enter, Leave and Over", content: <OnMouse1/>, isFlex: true},
      {id: 2, title: "Mouse Down, Move, and UP", content: <OnMouse2/>, isFlex: false},
      {id: 3, title: "Drag events", content: <Drag data={sampleList} />, isFlex: false},
      {id: 4, title: "Drag with Draggable", content: <DragWithDraggable />, isFlex: false},
      {id: 5, title: "Canvas with Mouse Down, Move, and UP", content: <Canvas />, isFlex: false}
    ]

    return (
      <ContentViewTemplate contentPrefix="mouseEvents" mainTitle="Mouse Event Handlers" contents={contents}/>
    );

}