import React, { useState, useEffect } from "react";

const CreateInputs = (props) => {

    return (
        <div className="field columns is-flex is-flex-direction-column">
            <div className="column">
                <p>Input Image</p>
            </div>
            <div className="column">
                <form method="post" onSubmit={props.submitHandler}>
                    <div className="imageInputContainer">
                        <label className="imageInputLabel">
                            <input
                                className="imageInput"
                                type="image"
                                alt="inputImage"
                                id="imageInput"
                                name="imageInput"
                                onChange={props.changeHandler}
                            />
                        </label>
                    </div>
                    <button
                        className="button is-responsive mt-3"
                        type="submit"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    )
}

const CurrentSelectedView = (props) => {

    return (
        <div className="column">
            <div>
                <p className="mb-3">Current Selected</p>
                {props.selected.toString()}
            </div>
        </div>
    )

}

const SubmittedView = (props) => {

    return (
        <div className="column notification is-primary mt-3">
            <div>
                <p className="mb-3">Submitted</p>
                {props.submitted.toString()}
            </div>
        </div>
    )

}

export function ImageInput (props) {

    const [titleColor] = useState('dimgray')
    const [submitted, setSubmitted] = useState(false);
    const [selected, setSelected] = useState(false);

    const changeHandler = (e) => {
        selected ? setSelected(false) : setSelected(true)
    }

    useEffect(() => {
        console.log(selected)
    }, [selected])


    const submitHandler = (e) => {
        e.preventDefault();
        submitted ? setSubmitted(false) : setSubmitted(true) 
    }

    useEffect(() => {
        console.log(submitted)
    }, [submitted])

    return (
        <div className='column is-full' >
        <h4 className="subtitle is-4 is-responsive" style={{color: titleColor, paddingLeft: 5, paddingTop: 2}}>
            Image (input type image works as form submit)
        </h4>
            <div className='columns'>
                <div className="column is-half">
                    <CreateInputs changeHandler={changeHandler} submitHandler={submitHandler}/>
                </div>
                <div className="column is-half columns is-flex is-flex-direction-column">
                    <CurrentSelectedView selected={selected} />
                    {submitted ? <SubmittedView submitted={submitted} /> : ""}
                </div>
            </div>
        </div>
    )
}