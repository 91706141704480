import React from 'react';

function CreateMenuItem(props) {
    const title = props.title
    return <li><a href={`#${props.contentPrefix}${props.number}`}>{title}</a></li>
}

function CreateMenu(props) {
    const menuItems = props.contents.map((item, key) => {
        return <CreateMenuItem contentPrefix={props.contentPrefix} title={item.title} key={`${props.contentPrefix}_${item.id}`} number={item.id}/>
    });
    
    return (
        <ul className='menu-list'>
            {menuItems}
        </ul>
    )
}

function CreateContents(props) {
    const contents = props.contents;
    const renderedContents = contents.map((item, key) => {
        const contentArr = []
        if(item.content && key !== contents.length - 1) {
            contentArr.push(
                <div id={`${props.contentPrefix}${item.id}`} key={`${props.contentPrefix}_${item.id}`} className={item.isFlex ? 'box columns is-flex is-flex-direction-column block mb-5' : 'box columns block mb-5'}>
                    {item.content}
                </div>
            )
        } else if (item.content && key === contents.length - 1) {
            contentArr.push(
                <div id={`${props.contentPrefix}${item.id}`} key={`${props.contentPrefix}_${item.id}`} className={item.isFlex ? 'box columns is-flex is-flex-direction-column block' : 'box columns block'}>
                    {item.content}
                </div>
            )
        }
        return contentArr
    })

    return (
        [renderedContents]
    )

}
  
export function ContentViewTemplate(props) {
    return (
        <section>
            <div id={`${props.contentPrefix}0`} className='block' style={{marginBottom: "3rem"}}>
                <h2 className='subtitle'>{props.mainTitle}</h2>
            </div>
            <div className='columns'>
                <aside className='menu column is-2 mr-3'>
                <div className='block menu-label'>
                    <h3>Index</h3>
                    <CreateMenu contents={props.contents} contentPrefix={props.contentPrefix} />
                </div>
                </aside>
                <div className='column'>
                    <div className='box columns is-flex is-flex-direction-column'>
                        <CreateContents contents={props.contents} contentPrefix={props.contentPrefix}/>
                    </div>
                </div>
            </div>
        </section>
    )
}