import React from 'react';
import { ContentViewTemplate } from './ContentViewTemplate';
import { Checkbox } from '../FormEventHandlers/Checkbox';
import { RadioButton } from '../FormEventHandlers/RadioButton';
import { Range } from '../FormEventHandlers/Range';
import { SingleSelect } from '../FormEventHandlers/SingleSelect';
import { MultipleSelect } from '../FormEventHandlers/MultipleSelect';
import { SingleSelectInBulma } from '../FormEventHandlers/SingleSelectInBulmaStyle';
import { ColorSelect } from '../FormEventHandlers/ColorSelect';

const sampleList = [
    {
      id: 0,
      name: "Apple",
      quantity: 10,
      price: 100,
    },
    {
      id: 1,
      name: "Banana",
      quantity: 20,
      price: 200,
    },
    {
      id: 2,
      name: "Cherry",
      quantity: 30,
      price: 300,
    },
  ]

const contents = [
    //id of Top have to be 0 because it is linked to mainTitle element.
    {id: 0, title: "Top"},
    {id: 1, title: "Checkbox", content: <Checkbox data={sampleList}/>, isFlex: true},
    {id: 2, title: "Radio Button", content: <RadioButton data={sampleList}/>, isFlex: true},
    {id: 3, title: "Range", content: <Range/>, isFlex: true},
    {id: 4, title: "Single Select", content: <SingleSelect data={sampleList}/>, isFlex: true},
    {id: 5, title: "Multiple Select", content: <MultipleSelect data={sampleList}/>, isFlex: true},
    {id: 6, title: "Single Select in Bulma style", content: <SingleSelectInBulma data={sampleList}/>, isFlex: true},
    {id: 7, title: "Color", content: <ColorSelect/>, isFlex: true},
  ]

export function FormEventHandlerCollection1 (props) {

    return (
        <ContentViewTemplate contentPrefix="formEvents1" mainTitle="Form Event Handlers 1" contents={contents}/>
      );
}