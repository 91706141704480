import React, { useState } from "react";

const CreateInputs = (props) => {

    return (
        <div className="field">
            <form method="post" onSubmit={props.submitHandler}>
                <p className="mb-3">Input Date</p>
                <div className="control">
                <input type="date" id="dateInput" name="dateInput" value={props.selected} min={props.minDate} max={props.maxDate} onChange={props.changeHandler}/>
                <label className="ml-3" htmlFor="dateInput">Input type date</label>
                </div>
                <button
                    className="button is-responsive mt-3"
                    type="submit"
                >
                    Submit
                </button>
            </form>
        </div>
    )
}

const CurrentSelectedView = (props) => {

    return (
        <div className="column">
            <div>
                <p className="mb-3">Current Selected</p>
                {props.selected}
            </div>
        </div>
    )

}

const SubmittedView = (props) => {

    return (
        <div className="column notification is-primary mt-3">
            <div>
                <p className="mb-3">Submitted</p>
                {props.submitted ? props.submitted : "Not selected"}
            </div>
        </div>
    )

}

export function DateInput (props) {

    const [titleColor] = useState('dimgray')
    const [date] = useState(new Date())
    
    const [minDate] = useState("2018-01-01")
    const [maxDate] = useState("2030-12-31")
    const [submitted, setSubmitted] = useState("")

    const initialDate = () => {
        const dateISOString = date.toISOString()
        const dateEndIndex = dateISOString.indexOf("T")
        return dateISOString.substring(0, dateEndIndex)
    }

    const [selected, setSelected] = useState(initialDate())

    const changeHandler = (e) => {
        console.log(Date())
        setSelected(e.target.value)
    }

    const submitHandler = (e) => {
        e.preventDefault();
        setSubmitted(selected)
    }

    return (
        <div className='column is-full' >
        <h4 className="subtitle is-4 is-responsive" style={{color: titleColor, paddingLeft: 5, paddingTop: 2}}>
            Date
        </h4>
            <div className='columns'>
                <div className="column is-half">
                    <CreateInputs selected={selected} minDate={minDate} maxDate={maxDate} changeHandler={changeHandler} submitHandler={submitHandler}/>
                </div>
                <div className="column is-half columns is-flex is-flex-direction-column">
                    <CurrentSelectedView selected={selected}/>
                    {submitted ? <SubmittedView submitted={submitted}/> : ""}
                </div>
            </div>
        </div>
    )
}