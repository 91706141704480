import React, { useState } from "react";

const CheckEmail = (emailAddress) => {

    const emailRegex = new RegExp(
        '^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9-]+\\.)+[a-zA-Z0-9]{2,}$'
      );
    return emailRegex.test(emailAddress);
}

const CreateInputs = (props) => {

    return (
        <div className="field columns is-flex is-flex-direction-column">
            <div className="column">
                <p>Input Email</p>
            </div>
            <div className="column">
                <form method="post" onSubmit={props.submitHandler}>
                    <label htmlFor="email">Email: </label>
                    <p className="mb-3 control has-icons-left has-icons-right" style={{width: "80%"}} >
                        <input
                            className="input is-primary"
                            type="email"
                            id="emailInput"
                            name="emailInput"
                            placeholder="Email"
                            onChange={props.changeHandler}
                        />
                        <span className="icon is-small is-left">
                            <i className="fas fa-envelope"></i>
                        </span>
                        <span className="icon is-small is-right">
                            <i className="fas fa-check"></i>
                        </span>
                    </p>
                    <button
                        className="button is-responsive mt-3"
                        type="submit"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    )
}

const CurrentSelectedView = (props) => {

    return (
        <div className="column">
            <div>
                <p className="mb-3">Current Selected</p>
                {props.selected}
            </div>
            <div
            className="column"
            style={{
                height: "100%",
                width: "100%",
                borderRadius: 6,
                backgroundColor: props.selected
            }}></div>
        </div>
    )

}

const SubmittedView = (props) => {

    return (
        <div className="column notification is-primary mt-3">
            <div>
                <p className="mb-3">Submitted</p>
                {props.submitted ? props.submitted : "Not selected"}
            </div>
        </div>
    )

}

export function EmailInput (props) {

    const [titleColor] = useState('dimgray')
    const [submitted, setSubmitted] = useState("")
    const [selected, setSelected] = useState("someone@example.com")
    const [emailError, setEmailError] = useState(false);

    const changeHandler = (e) => {
        setSelected(e.target.value)
    }

    const submitHandler = (e) => {
        e.preventDefault();
        if (!CheckEmail(selected)) {
            setEmailError(true);
            // ここでメールアドレスをサーバーに送信し、処理を行います。
            alert('emailアドレスの形式が不正です');
            return;
        }

        setEmailError(false);

        if (!emailError) {
            try {
                setSubmitted(selected)
                setSelected('');
            } catch (error) {
                console.log(error)
                setSelected('送信エラー');
            }
        } else {
            alert('emailアドレスが異常です');
            return;
        }

    }

    return (
        <div className='column is-full' >
        <h4 className="subtitle is-4 is-responsive" style={{color: titleColor, paddingLeft: 5, paddingTop: 2}}>
            Email
        </h4>
            <div className='columns'>
                <div className="column is-half">
                    <CreateInputs selected={selected} changeHandler={changeHandler} submitHandler={submitHandler}/>
                </div>
                <div className="column is-half columns is-flex is-flex-direction-column">
                    <CurrentSelectedView selected={selected}/>
                    {submitted ? <SubmittedView submitted={submitted}/> : ""}
                </div>
            </div>
        </div>
    )
}