import React, { useState } from "react";

const CreateInputs = (props) => {
    const inputList = props.data.map((item, key) => {
        return (
            <div key={`checkbox_${item.id}`}>
                <input 
                    type="checkbox"
                    id={`checkbox_${item.id}`}
                    name="checkbox"
                    value={item.name}
                    onChange={props.setChecked}
                />
                <label className="ml-3" htmlFor={`checkbox_${item.id}`}>
                    {item.name}
                </label>
            </div>
        )
    })

    return (
        <div>
            <form method="post" onSubmit={props.handleSubmit}>
                <p className="mb-3">Checkbox Items</p>
                {inputList}
                <button
                    className="button is-responsive mt-3"
                    type="submit"
                >
                    Submit
                </button>
            </form>
        </div>
    )
}

const CurrentSelectedView = (props) => {
    const checkedItems = props.checked.map((item, key) => {
        return (
            <li key={`selectedItem_${key}`}>
                {item}
            </li>
        )
    })

    return (
        <div className="column">
            <p className="mb-3">Current Checked</p>
            <ul>
                {checkedItems}
            </ul>
        </div>
    )

}

const SubmittedView = (props) => {
    const submittedItems = props.submitted.map((item, key) => {
        return (
            <li key={`submittedItem_${key}`}>
                {item}
            </li>
        )
    })

    return (
        <div className="column notification is-primary mt-3">
            <p className="mb-3">Submitted</p>
            <ul>
                {submittedItems}
            </ul>
        </div>
    )

}

export function Checkbox (props) {

    const [titleColor] = useState('dimgray')
    const [checked, setChecked] = useState([])
    const [submitted, setSubmitted] = useState([])

    const doCheckItem = (e) => {
        const checkedSwap = [...checked]
        if(e.target.checked) {
            setChecked([...checkedSwap, e.target.value])
        } else {
            setChecked(checkedSwap.filter(item => item !== e.target.value))
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        setSubmitted(checked)
    }

    return (
        <div className='column is-full' >
            <h4 className="subtitle is-4 is-responsive" style={{color: titleColor, paddingLeft: 5, paddingTop: 2}}>
                Checkbox events (onChange, onSubmit)
            </h4>
            <div className='columns'>
                <div className="column is-half">
                    <CreateInputs data={props.data} setChecked={doCheckItem} handleSubmit={handleSubmit}/>
                </div>
                <div className="column is-half columns is-flex is-flex-direction-column">
                    <CurrentSelectedView checked={checked}/>
                    {submitted.length ? <SubmittedView submitted={submitted}/> : ""}
                </div>
            </div>
        </div>
    );
}