import React, { useState } from "react";

const CreateInputs = (props) => {

    return (
        <div className="field">
            <form method="post" onSubmit={props.submitHandler}>
                <p className="mb-3">Input Time</p>
                <div className="control">
                <input type="time" id="timeInput" name="timeInput" value={props.selected} min={props.minTime} max={props.maxTime} onChange={props.changeHandler}/>
                <label className="ml-3" htmlFor="timeInput">Input type time</label>
                </div>
                <button
                    className="button is-responsive mt-3"
                    type="submit"
                >
                    Submit
                </button>
            </form>
        </div>
    )
}

const CurrentSelectedView = (props) => {

    return (
        <div className="column">
            <div>
                <p className="mb-3">Current Selected</p>
                {props.selected}
            </div>
        </div>
    )

}

const SubmittedView = (props) => {

    return (
        <div className="column notification is-primary mt-3">
            <div>
                <p className="mb-3">Submitted</p>
                {props.submitted ? props.submitted : "Not selected"}
            </div>
        </div>
    )

}

export function TimeInput (props) {

    const [titleColor] = useState('dimgray')
    const [date] = useState(new Date())
    
    const [minTime] = useState("00:00")
    const [maxTime] = useState("23:59")
    const [submitted, setSubmitted] = useState("")

    const initialTime = () => {
        const currentHour = date.getHours();
        const currentMinute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
        return `${currentHour}:${currentMinute}`
    }

    const [selected, setSelected] = useState(initialTime())

    const changeHandler = (e) => {
        setSelected(e.target.value)
    }

    const submitHandler = (e) => {
        e.preventDefault();
        setSubmitted(selected)
    }

    return (
        <div className='column is-full' >
        <h4 className="subtitle is-4 is-responsive" style={{color: titleColor, paddingLeft: 5, paddingTop: 2}}>
            Time
        </h4>
            <div className='columns'>
                <div className="column is-half">
                    <CreateInputs selected={selected} minTime={minTime} maxTime={maxTime} changeHandler={changeHandler} submitHandler={submitHandler}/>
                </div>
                <div className="column is-half columns is-flex is-flex-direction-column">
                    <CurrentSelectedView selected={selected}/>
                    {submitted ? <SubmittedView submitted={submitted}/> : ""}
                </div>
            </div>
        </div>
    )
}