import React, { useState } from "react";

const CreateInputs = (props) => {

    return (
        <div className="field">
            <form method="post" onSubmit={props.submitHandler}>
                <p className="mb-3">Input DateTimeLocal</p>
                <div className="control">
                <input type="datetime-local" id="dateTimeLocalInput" name="dateTimeLocalInput" value={props.selected} min={props.mindateTimeLocal} max={props.maxdateTimeLocal} onChange={props.changeHandler}/>
                <label className="ml-3" htmlFor="dateTimeLocalInput">Input type DateTimeLocal</label>
                </div>
                <button
                    className="button is-responsive mt-3"
                    type="submit"
                >
                    Submit
                </button>
            </form>
        </div>
    )
}

const CurrentSelectedView = (props) => {

    return (
        <div className="column">
            <div>
                <p className="mb-3">Current Selected</p>
                {props.selected}
            </div>
        </div>
    )

}

const SubmittedView = (props) => {

    return (
        <div className="column notification is-primary mt-3">
            <div>
                <p className="mb-3">Submitted</p>
                {props.submitted ? props.submitted : "Not selected"}
            </div>
        </div>
    )

}

export function DateTimeLocalInput (props) {

    const [titleColor] = useState('dimgray')
    const [date] = useState(new Date())
    
    const [mindateTimeLocal] = useState("2018-06-07T00:00")
    const [maxdateTimeLocal] = useState("2030-12-31T23:59")
    const [submitted, setSubmitted] = useState("")

    const initialDateTimeLocal = () => {
        const diff = date.getTimezoneOffset() * 60 * 1000
        const localTime = new Date(date - diff)
        const isoFormatLocalTime = localTime.toISOString()
        return isoFormatLocalTime.slice(0,16) //分まで表示
    }

    const [selected, setSelected] = useState(initialDateTimeLocal())

    const changeHandler = (e) => {
        setSelected(e.target.value)
    }

    const submitHandler = (e) => {
        e.preventDefault();
        setSubmitted(selected)
    }

    return (
        <div className='column is-full' >
        <h4 className="subtitle is-4 is-responsive" style={{color: titleColor, paddingLeft: 5, paddingTop: 2}}>
            DateTimeLocal
        </h4>
            <div className='columns'>
                <div className="column is-half">
                    <CreateInputs selected={selected} mindateTimeLocal={mindateTimeLocal} maxdateTimeLocal={maxdateTimeLocal} changeHandler={changeHandler} submitHandler={submitHandler}/>
                </div>
                <div className="column is-half columns is-flex is-flex-direction-column">
                    <CurrentSelectedView selected={selected}/>
                    {submitted ? <SubmittedView submitted={submitted}/> : ""}
                </div>
            </div>
        </div>
    )
}