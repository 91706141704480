import React from 'react';
import { MouseEventHandlerCollection } from './Controllers/MouseEventHandlerCollection';
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import { FormEventHandlerCollection1 } from './Controllers/FormEventHandlerCollection1';
import { FormEventHandlerCollection2 } from './Controllers/FormEventHandlerCollection2';
import { FormEventHandlerCollection3 } from './Controllers/FormEventHandlerCollection3';

const routeItems = [
    {
        name: "Home",
        className: "Home",
        element: <MouseEventHandlerCollection />,
        path: "/",
        isExact: true
    },
    {
        name: "Mouse Events",
        className: "MouseEventHandlerCollection",
        element: <MouseEventHandlerCollection />,
        path: "/MouseEventHandlerCollection",
        isExact: false
    },
    {
        name: "Form Events 1",
        className: "FormEventHandlerCollection1",
        element: <FormEventHandlerCollection1 />,
        path: "/FormEventHandlerCollection1",
        isExact: false
    },
    {
        name: "Form Events 2",
        className: "FormEventHandlerCollection2",
        element: <FormEventHandlerCollection2 />,
        path: "/FormEventHandlerCollection2",
        isExact: false
    },
    {
        name: "Form Events 3",
        className: "FormEventHandlerCollection3",
        element: <FormEventHandlerCollection3 />,
        path: "/FormEventHandlerCollection3",
        isExact: false
    },
]

const ListRendering = (props) => {
    const listArr = [...props.list]
    const renderedItems = listArr.map((item, key) => {
        return (
            <NavLink
                to={item.path}
                key={`nav_${key}`}
                className={({ isActive }) => 
                    isActive ? "is-active" : ""
                }
            >
                {item.name}
            </NavLink>
        )
    });
    return (
        <ul id="globalNav">
        {renderedItems}
        </ul>
    );
}

const RoutesRendering = (props) => {
    const listArr = [...props.list]
    const renderedItems = listArr.map((item, key) => {
        return (
            <Route path={item.path} key={`route${key}`} element={item.element} />
        )
    })
    return (
        <Routes>
            {renderedItems}
        </Routes>
    )
}


export function ViewSelector() {
    return (
        <BrowserRouter>
        <div className='container is-fluid'>
            <nav>
                <div className="tabs">
                    <ListRendering list={routeItems}/>
                </div>
            </nav>
            <div className='block'></div>
            <RoutesRendering list={routeItems} />
        </div>
        </BrowserRouter>
    )
}



