import React, { useState } from "react";

const CreateInputs = (props) => {
    const displayDropDownMenu = (e) => {
        const dropDownParentNode = e.currentTarget.closest(".dropdown")
        dropDownParentNode.classList.add("is-active")
    }

    const highLightMenu = (e) => {
        const mouseEnterItem = e.currentTarget
        mouseEnterItem.classList.add("is-active")
    }

    const deHighLightMenu = (e) => {
        const mouseLeaveItem = e.currentTarget
        mouseLeaveItem.classList.remove("is-active")
    }

    const menuClickHandler = (e) => {
        props.changeHandler(e)
        const dropDownParentNode = e.currentTarget.closest(".dropdown")
        dropDownParentNode.classList.remove("is-active")
    }

    const inputList = props.data.map((item, key) => {
        return (
                <button className="dropdown-item" key={`singleSelectBulma_${key}`} value={item.name} onMouseEnter={highLightMenu} onMouseLeave={deHighLightMenu} onClick={menuClickHandler}>
                    {item.name}
                </button>
        )
    })

    return (
        <div>
            <div className="dropdown columns is-flex is-flex-direction-column">
                <div className="column">
                    <p>Single Select Item</p>
                </div>
                <div className="dropdown-trigger column">
                    <button className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={displayDropDownMenu}>
                        <span>Dropdown button in Bulma Style</span>
                        <span className="icon is-small">
                            <i className="fas fa-angle-down" aria-hidden="true"></i>
                        </span>
                    </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    <div className="dropdown-content">
                        {inputList}
                    </div>
                </div>
            </div>
            <button
                className="button is-responsive mt-3"
                type="submit"
                onClick={props.submitHandler}
            >
                Submit
            </button>
        </div>
    )
}

const CurrentSelectedView = (props) => {

    return (
        <div className="column">
            <p className="mb-3">Current Selected</p>
            {props.selected}
        </div>
    )

}

const SubmittedView = (props) => {

    return (
        <div className="column notification is-primary mt-3">
            <p className="mb-3">Submitted</p>
            {props.submitted ? props.submitted : "Not selected"}
        </div>
    )

}

export function SingleSelectInBulma (props) {

    const [titleColor] = useState('dimgray')
    const [selected, setSelected] = useState(`${props.data[0].name}`)
    const [submitted, setSubmitted] = useState("")

    const changeHandler = (e) => {
        setSelected(e.target.innerHTML)
    }

    const submitHandler = (e) => {
        e.preventDefault();
        setSubmitted(selected)
    }

    return (
        <div className='column is-full' >
        <h4 className="subtitle is-4 is-responsive" style={{color: titleColor, paddingLeft: 5, paddingTop: 2}}>
            Single Select in <a target="_blank" rel="noreferrer" href="https://bulma.io/documentation/components/dropdown/">Bulma Style</a> (onChange, onSubmit)
        </h4>
        <p>Bulma seems to have is-active class in only a or button for dropdown-item</p>
        <div className="block"></div>
            <div className='columns'>
                <div className="column is-half">
                    <CreateInputs data={props.data} changeHandler={changeHandler} submitHandler={submitHandler}/>
                </div>
                <div className="column is-half columns is-flex is-flex-direction-column">
                    <CurrentSelectedView selected={selected}/>
                    {submitted ? <SubmittedView submitted={submitted}/> : ""}
                </div>
            </div>
        </div>
    )
}