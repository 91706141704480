import React, { useState, useRef } from "react";


const CheckPasswordStrength = (password) => {
    
    // passowrdの強度をチェックする
    // passwordの要件は以下の通り
    // 1. 8文字以上
    // 2. 少なくとも1つの小文字が含まれる: (?=.*[a-z])
    // 3. 少なくとも1つの大文字が含まれる: (?=.*[A-Z])
    // 4. 少なくとも1つの数字が含まれる: (?=.*[0-9])
    // 5. 少なくとも1つの特殊文字が含まれる: (?=.*[!@#\$%\^&\*])
    // 6. 1. 2. 3. 4. 5. の条件を満たす

    const strongRegex = new RegExp(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
      );
    return strongRegex.test(password);
}

const CreateInputs = (props) => {

    return (
        <div className="field columns is-flex is-flex-direction-column">
            <div className="column">
                <h5 className="block">Input Password</h5>
                <div className="content">
                <p>パスワードの要件</p>
                <ul>
                    <li>8文字以上</li>
                    <li>少なくとも1つの小文字が含まれる: (?=.*[a-z])</li>
                    <li>少なくとも1つの大文字が含まれる: (?=.*[A-Z])</li>
                    <li>少なくとも1つの数字が含まれる: (?=.*[0-9])</li>
                    <li>少なくとも1つの特殊文字が含まれる: (?=.*[!@#\$%\^&\*])</li>
                    <li>上記の条件をすべて満たす</li>
                </ul>
                </div>
            </div>
            <div className="column">
                <form method="post" onSubmit={props.submitHandler}>
                    <label htmlFor="password">Password: </label>
                    <p className="mb-3 control has-icons-left has-icons-right" style={{width: "80%"}} >
                        <input
                            className="input is-primary"
                            type="password"
                            id="passwordInput"
                            name="passwordInput"
                            placeholder="Password"
                            onChange={props.changeHandler}
                            required
                        />
                        <span className="icon is-small is-left">
                            <i className="fas fa-key"></i>
                        </span>
                        <span className="icon is-small is-right">
                            <i className="fas fa-check"></i>
                        </span>
                    </p>
                    <button
                        className="button is-responsive mt-3"
                        type="submit"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    )
}

const CurrentSelectedView = (props) => {

    return (
        <div className="column">
            <div>
                <p className="mb-3">Current Selected</p>
                {props.selected}
            </div>
            <div
            className="column"
            style={{
                height: "100%",
                width: "100%",
                borderRadius: 6,
                backgroundColor: props.selected
            }}></div>
        </div>
    )

}

const SubmittedView = (props) => {

    return (
        <div className="column notification is-primary mt-3">
            <div>
                <p className="mb-3">Submitted</p>
                {props.submitted ? props.submitted : "Not selected"}
            </div>
        </div>
    )

}

export function PasswordInput (props) {

    const [titleColor] = useState('dimgray')
    const [submitted, setSubmitted] = useState("")
    const [selected, setSelected] = useState("P@ssw0rd")
    const [passwordError, setPasswordError] = useState(false);
    const [requestCount, setRequestCount] = useState(0);
    const requestLimit = 5;
    const lastRequestTimestamp = useRef(Date.now());

    const changeHandler = (e) => {
        setSelected(e.target.value)
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        if (requestCount >= requestLimit) {
            alert('リクエスト制限に達しました。しばらく時間を置いてから再試行してください。');
            return;
        }
        if (!CheckPasswordStrength(selected)) {
            setPasswordError(true);
            alert('パスワードの要件を満たしません');
            return;
        }
        setPasswordError(false);

        // パスワードをサーバに送信する処理をここに記述します。
        // 通信はSSL/TLSを使用して暗号化されることを確認してください。
        if (passwordError) {
            try {
                // この例では10秒間にリクエスト制限回数を超えないように制限をかけています。
                if (Date.now() - lastRequestTimestamp.current > 10000) {
                setRequestCount(0);
                }
                setRequestCount((prevCount) => prevCount + 1);
                lastRequestTimestamp.current = Date.now();
        
                // サーバ側のAPIを呼び出すコード（例：Axiosを使ったPOSTリクエスト）
                // const response = await axios.post('https://your-api.example.com/password-reset', { password });
                setSubmitted(selected)
        
                // 送信に成功した場合、状態をリセットする
                setSelected('');
                setRequestCount(0);
            } catch (error) {
                setSelected('送信エラー');
            }
        } else {
            alert('パスワードが以上です');
            return;
        }
    };

    return (
        <div className='column is-full' >
        <h4 className="subtitle is-4 is-responsive" style={{color: titleColor, paddingLeft: 5, paddingTop: 2}}>
            Password
        </h4>
            <div className='columns'>
                <div className="column is-half">
                    <CreateInputs selected={selected} changeHandler={changeHandler} submitHandler={submitHandler}/>
                </div>
                <div className="column is-half columns is-flex is-flex-direction-column">
                    <CurrentSelectedView selected={selected}/>
                    {submitted ? <SubmittedView submitted={submitted}/> : ""}
                </div>
            </div>
        </div>
    )
}