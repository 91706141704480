import React from 'react';

export class OnMouse2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: ["10", "ff", "df"],
            buttonTextColor: 'dimgray',
            textcolor: 'dimgray',
            left: 0,
            top: 0,
            shiftX: 0,
            shiftY: 0,
            dragging: false,
            parentDivPosition: {},
            draggableDivStyle: {
                display: "grid",
                placeItems: "center",
                color: "white",
                backgroundColor: "dimgray",
                position: "absolute",
                left: 0,
                top: 10,
                width: 120,
                height: 35,
                borderRadius: 6
            },
            targetDivStyle: {
                position: "absolute",
                display: "grid",
                placeItems: "center",
                right: 20,
                top: 20,
                width:200,
                height:100,
                borderRadius: 6,
                border: "dotted",
                borderColor: "dimgray",
                borderWidth: 2
            },
            currentDroppable: null
        }
        this.onMouseDownHandler = this.onMouseDownHandler.bind(this);
        this.onMouseMoveHandler = this.onMouseMoveHandler.bind(this);
        this.onMouseUpHandler = this.onMouseUpHandler.bind(this);
        this.dragStartHandelr = this.dragStartHandelr.bind(this);
        this.getRectFunc = this.getRectFunc.bind(this);
    }

    componentDidMount(){
        const parentDivPosition = this.getRectFunc("dragParentDiv");
        this.setState({
            parentDivPosition: parentDivPosition
        })
    }
    
    getRectFunc(element) {
        const getRect = document.getElementById(element);
        return getRect.getBoundingClientRect();
    }

    onMouseDownHandler(e) {
        const draggedElement = e.target;
        const shiftX = e.clientX - draggedElement.getBoundingClientRect().left;
        const shiftY = e.clientY - draggedElement.getBoundingClientRect().top;
        //console.log("Drag start: X: " + (e.clientX - this.state.parentDivPosition.left - shiftX) + " | Y: " + (e.clientY - this.state.parentDivPosition.top - shiftY))
        //console.log(`shiftX: ${shiftX}, shiftY: ${shiftY}`)
        const draggableDivStyle = {...this.state.draggableDivStyle}
        draggableDivStyle.backgroundColor = "lavender"
        this.setState({dragging: true, draggableDivStyle: draggableDivStyle, shiftX: shiftX, shiftY: shiftY})
    }

    onMouseMoveHandler(e) {
        if (this.state.dragging) {
            const draggableDivStyle = {...this.state.draggableDivStyle};
            draggableDivStyle.left = e.clientX - this.state.parentDivPosition.left - this.state.shiftX;
            draggableDivStyle.top = e.clientY - this.state.parentDivPosition.top - this.state.shiftY
            this.setState({draggableDivStyle: draggableDivStyle})
            e.target.hidden = true;


        }
    }

    onMouseUpHandler(e) {
        const draggableDivStyle = {...this.state.draggableDivStyle};
        draggableDivStyle.backgroundColor = "dimgray";
        draggableDivStyle.left = e.clientX - this.state.parentDivPosition.left - this.state.shiftX;
        draggableDivStyle.top = e.clientY - this.state.parentDivPosition.top - this.state.shiftY
        this.setState({dragging: false, draggableDivStyle: draggableDivStyle, shiftX: 0, shiftY: 0})
    }

    dragStartHandelr(e) {
        e.preventDefault();
    }

    render() {
        return (
            <div className='column is-full' >
                <h4 className="subtitle is-4 is-responsive" style={{color: this.state.textcolor, paddingLeft: 5, paddingTop: 2}}>
                    Mouse Down, Move, and UP
                </h4>
                <p>This section is referred to <a target="_blank" rel="noreferrer" href="https://ja.javascript.info/mouse-drag-and-drop">JAVASCRIPT.INFO</a>. Thank you so much !</p>
                <div id="dragParentDiv" style={{height: 200, position: "relative"}}>
                    <div
                        style={this.state.draggableDivStyle}
                        id="draggableButton"
                        draggable="true"
                        onMouseDown={this.onMouseDownHandler}
                        onMouseMove={this.onMouseMoveHandler}
                        onMouseUp={this.onMouseUpHandler}
                        onDragStart={this.dragStartHandelr}
                        >
                    Drag me
                    </div>
                    <p style={{position: "absolute", top: 175}}>Current relative position: X: {this.state.draggableDivStyle.left} Y: {this.state.draggableDivStyle.top}</p>
                </div>
            </div>
        );
    }
}